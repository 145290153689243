exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-advertisements-js": () => import("./../../../src/pages/advertisements.js" /* webpackChunkName: "component---src-pages-advertisements-js" */),
  "component---src-pages-birthday-celebration-payment-js": () => import("./../../../src/pages/birthday-celebration-payment.js" /* webpackChunkName: "component---src-pages-birthday-celebration-payment-js" */),
  "component---src-pages-booking-forms-js": () => import("./../../../src/pages/booking-forms.js" /* webpackChunkName: "component---src-pages-booking-forms-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-display-notification-js": () => import("./../../../src/pages/display_notification.js" /* webpackChunkName: "component---src-pages-display-notification-js" */),
  "component---src-pages-educational-activities-js": () => import("./../../../src/pages/educational-activities.js" /* webpackChunkName: "component---src-pages-educational-activities-js" */),
  "component---src-pages-experiments-js": () => import("./../../../src/pages/experiments.js" /* webpackChunkName: "component---src-pages-experiments-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovation-hub-js": () => import("./../../../src/pages/innovation-hub.js" /* webpackChunkName: "component---src-pages-innovation-hub-js" */),
  "component---src-pages-installations-js": () => import("./../../../src/pages/installations.js" /* webpackChunkName: "component---src-pages-installations-js" */),
  "component---src-pages-membership-js": () => import("./../../../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-node-add-booking-for-paid-visit-js": () => import("./../../../src/pages/node/add/booking_for_paid_visit.js" /* webpackChunkName: "component---src-pages-node-add-booking-for-paid-visit-js" */),
  "component---src-pages-node-add-booking-js": () => import("./../../../src/pages/node/add/booking.js" /* webpackChunkName: "component---src-pages-node-add-booking-js" */),
  "component---src-pages-notifications-js": () => import("./../../../src/pages/notifications.js" /* webpackChunkName: "component---src-pages-notifications-js" */),
  "component---src-pages-objectives-js": () => import("./../../../src/pages/objectives.js" /* webpackChunkName: "component---src-pages-objectives-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-paid-visit-payment-js": () => import("./../../../src/pages/paid-visit-payment.js" /* webpackChunkName: "component---src-pages-paid-visit-payment-js" */),
  "component---src-pages-payment-status-js": () => import("./../../../src/pages/payment-status.js" /* webpackChunkName: "component---src-pages-payment-status-js" */),
  "component---src-pages-payment-terms-conditions-js": () => import("./../../../src/pages/payment-terms&conditions.js" /* webpackChunkName: "component---src-pages-payment-terms-conditions-js" */),
  "component---src-pages-photo-galleries-js": () => import("./../../../src/pages/photo-galleries.js" /* webpackChunkName: "component---src-pages-photo-galleries-js" */),
  "component---src-pages-plan-a-visit-js": () => import("./../../../src/pages/plan-a-visit.js" /* webpackChunkName: "component---src-pages-plan-a-visit-js" */),
  "component---src-pages-planetarium-shows-js": () => import("./../../../src/pages/planetarium-shows.js" /* webpackChunkName: "component---src-pages-planetarium-shows-js" */),
  "component---src-pages-recruitment-js": () => import("./../../../src/pages/recruitment.js" /* webpackChunkName: "component---src-pages-recruitment-js" */),
  "component---src-pages-screen-reader-access-js": () => import("./../../../src/pages/screen-reader-access.js" /* webpackChunkName: "component---src-pages-screen-reader-access-js" */),
  "component---src-pages-shillong-science-centre-cancellation-refund-policy-js": () => import("./../../../src/pages/shillong-science-centre-cancellation&refund-policy.js" /* webpackChunkName: "component---src-pages-shillong-science-centre-cancellation-refund-policy-js" */),
  "component---src-pages-sponsored-visit-preview-js": () => import("./../../../src/pages/sponsored-visit-preview.js" /* webpackChunkName: "component---src-pages-sponsored-visit-preview-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms&conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-visit-schedule-policy-js": () => import("./../../../src/pages/visit-schedule-policy.js" /* webpackChunkName: "component---src-pages-visit-schedule-policy-js" */),
  "component---src-pages-website-policy-js": () => import("./../../../src/pages/website-policy.js" /* webpackChunkName: "component---src-pages-website-policy-js" */),
  "component---src-pages-whos-who-js": () => import("./../../../src/pages/who's-who.js" /* webpackChunkName: "component---src-pages-whos-who-js" */),
  "component---src-templates-display-experiment-js": () => import("./../../../src/templates/display_experiment.js" /* webpackChunkName: "component---src-templates-display-experiment-js" */),
  "component---src-templates-display-installation-js": () => import("./../../../src/templates/display_installation.js" /* webpackChunkName: "component---src-templates-display-installation-js" */)
}

